import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic08 from '../assets/images/windowdepressed.jpg'
import pic09 from '../assets/images/womanwall.jpg'
import pic10 from '../assets/images/familyinneed.jpg'
import pic11 from '../assets/images/ministry.jpg'
import pic12 from '../assets/images/single_mom.jpg'

const Landing = props => (
  <Layout>
    <Helmet>
      <title>Resources</title>
      <meta name="description" content="Resources" />
    </Helmet>

    <BannerLanding />

    <div id="main">
      {/* <section id="one">
        <div className="inner">
          <header className="major">
            <h2>Sed amet aliquam</h2>
          </header>
          <p>
            Nullam et orci eu lorem consequat tincidunt vivamus et sagittis
            magna sed nunc rhoncus condimentum sem. In efficitur ligula tate
            urna. Maecenas massa vel lacinia pellentesque lorem ipsum dolor.
            Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis
            libero. Nullam et orci eu lorem consequat tincidunt vivamus et
            sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula
            tate urna.
          </p>
        </div>
      </section> */}
      <section id="two" className="spotlights">
        <section>
          <Link to="/resources" className="image">
            <img src={pic08} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Help For Addicts</h3>
              </header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1' }}>
                  <p>
                    <a
                      href="https://boiserm.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Boise Rescue Mission
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.aa.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Alcoholics Anonymous
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.celebraterecovery.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Celebrate Recovery
                    </a>
                  </p>
                </div>
                <div style={{ flexGrow: '1' }}>
                  <p>
                    <a
                      href="http://www.chrysaliswomenidaho.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Chrysalis Transition Home
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://corpuschristiboise.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Corpus Christi House
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.abbeycarefoundation.com/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Abbeycare Foundation
                    </a>
                  </p>
                </div>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic09} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Women In Crisis</h3>
              </header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ flexGrow: '1' }}>
                  <p>
                    <a
                      href="https://boiserm.org/facilities/city-light/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      City Light
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://lifelinepcc.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Lifeline
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://pathpreg.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Path Pregnancy
                    </a>
                  </p>
                </div>
                <div style={{ flexGrow: '1' }}>
                  <p>
                    <a
                      href="https://birthright.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Birthright
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://www.loveinc.org/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Love INC
                    </a>
                  </p>
                </div>
              </div>
              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic10} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Help For Families In Need</h3>
              </header>
              <p>
                <a
                  href="https://www.feedingamerica.org/find-your-local-foodbank"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Find Your Foodbank
                </a>
              </p>
              <p>
                <a
                  href="https://giveinkind.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Give InKind
                </a>
              </p>
              <p>
                <a
                  href="https://worldrelief.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  World Relief
                </a>
              </p>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic11} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>National Ministries</h3>
              </header>
              <p>
                <a
                  href="https://www.compassion.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Compassion International
                </a>
              </p>
              <p>
                <a
                  href="https://www.samaritanspurse.org/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Samaritans Purse
                </a>
              </p>
              <p>
                <a
                  href="https://www.focusonthefamily.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Focus on the Family
                </a>
              </p>
              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
        <section>
          <Link to="/resources" className="image">
            <img src={pic12} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Widows and Single Parents</h3>
              </header>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <p>
                  When I was a teen I lost my dad to a heart attack. So I grew
                  up with a widowed mom in her 50’s. Since then I have known so
                  many widows, and many single moms and dads.
                </p>
                <p>
                  It is a tough life. My mom always felt she was too young to
                  take part in the retiree groups and too old to be with the
                  young moms. So she was just by herself…all the time. She quit
                  going to church and was in a very dark place. We had
                  appliances that broke and no one to fix them and no income
                  except social security. She was 56 when my dad died and hadn’t
                  worked for a long time. It was tough to find a job with
                  someone willing to train her.
                </p>
                <p>
                  I also have had many friends who were single parents at one
                  point. Trying to juggle job, shopping, school…it is endless.
                  Not only to keep up with a child but also to have any friends
                  or social life.{' '}
                </p>
                <p>
                  <strong>
                    This was the reason for starting Colossians 3:12 Ministries
                  </strong>{' '}
                  yet there are little resources out there to encourage this
                  demographic of people. We are still working at trying to
                  design something. All the other areas we have found resources
                  to list but this area has been a challenge.{' '}
                </p>
                <p>
                  The goal of Colossians 3:12 is to give people resources so
                  they know how to help and where to look when they see someone
                  in need. Right now after just launching this we have been
                  gathering information but we would like to be able to give
                  help where needed. I have started to have many interested and
                  asking questions. This is great! Our team is expanding and
                  within the next 6 months I “hope” we should be able to offer
                  assistance when someone inquires.{' '}
                </p>
              </div>

              {/* <ul className="actions">
                <li>
                  <Link to="/generic" className="button">
                    Learn more
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Landing
